import React, { useMemo } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Eye, Pencil } from 'lucide-react';



const DataTable = ({ data }) => {



    const columns = useMemo(
        () => [
            {
                accessorKey: 'indent_date',
                header: 'Indent Date',
                size: 150,
                Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString(),
            },
            {
                accessorKey: 'indent_number',
                header: 'Indent Number',
                size: 150,
            },
            {
                accessorKey: 'indent_branch_name',
                header: 'Branch Name',
                size: 200,
                Cell: ({ cell }) => (
                    <div className="max-h-10 overflow-y-auto">
                        {cell.getValue().split(' ')[1]}
                    </div>
                ),
            },
            {
                accessorKey: 'items',
                header: 'Item Titles',
                size: 300,
                Cell: ({ cell }) => (
                    <div className="max-h-10 overflow-y-auto">
                        {cell.getValue().map(item => (
                            <div key={item._id}>{item.item_title}</div>
                        ))}
                    </div>
                ),
            },
            {
                accessorKey: 'indent_remark',
                header: 'Remark',
                size: 200,
            },
            {
                accessorKey: 'indent_by_name',
                header: 'Indent By',
                size: 150,
            },
            {
                accessorKey: '_id',
                header: 'Action',
                size: 200,
                Cell: ({ cell }) => (
                    <div className='flex items-center gap-3'>
                        <Link to={`/details/${cell.getValue()}`}>
                            <button><Eye size={20} /></button>
                        </Link>
                        <Link to={`/edit/${cell.getValue()}`}>
                            <button><Pencil size={18} /></button>
                        </Link>
                    </div>
                ),
            },
            {
                accessorKey: 'indent_status',
                header: 'Status',
                size: 150,
            },
        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data,
        enableRowSelection: false,
        columnFilterDisplayMode: 'popover',
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        renderTopToolbarCustomActions: () => (
            <div className='flex gap-2 px-1 mt-1'>
                <button className='p-1 bg-[#EEEDEB] rounded-t-lg shadow-sm'>
                    <CSVLink data={exportToCsv()} filename={"table.csv"} className="text-sm">Export CSV</CSVLink>
                </button>
                <button className='p-1 bg-[#EEEDEB] shadow-sm rounded-t-lg text-sm' onClick={exportToPdf} >Export PDF</button>
            </div>
        ),
    });

    const exportToCsv = () => {
        const csvData = data.map(row => ({
            'Branch Name': row.indent_branch_name,
            'Indent By': row.indent_by_name,
            'Indent Date': new Date(row.indent_date).toLocaleDateString(),
            'Status': row.indent_status,
            'Remark': row.indent_remark,
            'Item Titles': row.items.map(item => item.item_title).join(', '),
        }));

        return csvData;
    };

    const exportToPdf = () => {
        const doc = new jsPDF();
        const tableColumn = ["SN.", "Branch Name", "Indent By", "Indent Date", "Status", "Remark", "Item Titles"];
        const tableRows = [];

        let sn = 1;
        data.forEach(row => {
            const rowData = [
                sn++,
                row.indent_branch_name,
                row.indent_by_name,
                new Date(row.indent_date).toLocaleDateString(),
                row.indent_status,
                row.indent_remark,
                row.items.map(item => item.item_title).join(', '),
            ];
            tableRows.push(rowData);
        });

        const pageWidth = doc.internal.pageSize.getWidth();
        const textWidth = doc.getStringUnitWidth("Indent Report") * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const textX = (pageWidth - textWidth) / 2;
        // Add a centered header
        doc.text("Indent Report", textX, 15);
        // Add the table
        doc.autoTable({
            startY: 20,
            head: [tableColumn],
            body: tableRows,
        });
        doc.save("polybond_indent.pdf");
    };

    return (
        <div>

            <MaterialReactTable table={table} />
        </div>
    );
};

export default DataTable;
