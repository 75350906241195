
import React, { useState } from 'react'
import { Line } from 'react-chartjs-2'
import { Chart as ChartJS } from "chart.js/auto"

const data =
    [
        {
            "id": 1,
            "year": 2016,
            "userGain": 80000,
            "userLost": 823
        },
        {

            "id": 2,
            "year": 2017,
            "userGain": 75000,
            "userLost": 900
        },
        {
            "id": 3,
            "year": 2018,
            "userGain": 90000,
            "userLost": 780
        },
        {
            "id": 4,
            "year": 2019,
            "userGain": 85000,
            "userLost": 50060
        },
        {
            "id": 5,
            "year": 2020,
            "userGain": 92000,
            "userLost": 800
        },
        {
            "id": 6,
            "year": 2021,
            "userGain": 97000,
            "userLost": 850
        },
        {
            "id": 7,
            "year": 2022,
            "userGain": 88000,
            "userLost": 1210
        },
        {
            "id": 8,
            "year": 2023,
            "userGain": 105000,
            "userLost": 123445
        },
        {
            "id": 9,
            "year": 2024,
            "userGain": 110000,
            "userLost": 820
        },
        {
            "id": 10,
            "year": 2025,
            "userGain": 115000,
            "userLost": 890
        }
    ]


const Dashboard = () => {

    const [userData, setUserData] = useState({
        labels: data.map((data) => data.year),
        datasets: [
            {
                label: "Users Gained",
                data: data.map((data) => data.userGain),
                backgroundColor: ["green"],
                borderColor: "black",
                borderWidth: 1,
                fill: false
            },
            {
                label: "Users Lost",
                data: data.map((data) => data.userLost),
                backgroundColor: ["red"],
                borderColor: "black",
                borderWidth: 1,
                fill: false
            },
        ]
    });

    return (
        <div className='flex-1 flex mt-5 w-full justify-center items-center'>
            {/* <div className='w-[40%] h-[40%]'>
                <Bar data={userData} />
            </div> */}
            <div className='flex flex-col w-full h-full p-2 gap-4 mx-auto'>
                <div className='flex w-full h-[50%] md:flex-row flex-col md:justify-around items-center'>
                    <div className='md:w-[48%] w-[98%] h-full bg-[#fdfdfd] flex flex-col justify-center items-center rounded shadow'>
                        <h1>PO Summary chart</h1>
                        <Line data={userData} />
                    </div>
                    <div className='md:w-[48%] w-[98%] h-full bg-[#fdfdfd] flex flex-col justify-center items-center rounded shadow'>
                        <h1>PO Summary chart</h1>
                        <Line data={userData} />
                    </div>
                </div>
                <div className='flex w-full h-[50%] md:flex-row flex-col md:justify-around items-center'>
                    <div className='md:w-[48%] w-[98%] h-full bg-[#fdfdfd] flex flex-col justify-center items-center rounded shadow'>
                        <h1>PO Summary chart</h1>
                        <Line data={userData} />
                    </div>
                    <div className='md:w-[48%] w-[98%] h-full bg-[#fdfdfd] flex flex-col justify-center items-center rounded shadow'>
                        <h1>PO Summary chart</h1>
                        <Line data={userData} />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Dashboard