import React, { useEffect, useState } from 'react';
import api from './Axios/api';
import { useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { ArrowDown, MoveDown, MoveUp } from 'lucide-react';
import BackBtn from './common/BackBtn';
import Swal from 'sweetalert2';



const PurchasePartyCard = ({ title, id, setSelectedParty, multipleApproved, selectedParty, partiesToAprove: partiesToApprove, price, otherCharges, highlight }) => {
  const [isSplit, setIsSplit] = useState(false);

  const handleButtonClick = () => {
    setIsSplit(true);
    setSelectedParty(id);
  };

  const close = () => {
    setSelectedParty(null);
  }

  return (
    <div className={`p-4 border rounded-lg text-center ${id === selectedParty ? 'bg-green-100' : 'bg-white'}`}>

      <h3 className="text-xl font-semibold">{title?.length > 25 ? title?.slice(0, 22) + "..." : title}</h3>
      <p className="text-2xl font-bold">{price}</p>
      <p className="text-gray-600">{otherCharges}</p>
      <div className="mt-2 flex justify-center">
        {!(id === selectedParty) ? (
          <button
            className={`py-1 px-4 rounded bg-blue-500 text-white `}
            onClick={handleButtonClick}
          >
            Select all rate
          </button>
        ) : (
          <div className="flex animate-split gap-1">
            <button className="py-1 px-4 rounded-l bg-red-500 text-white" onClick={() => close()}>X</button>
            <button className="py-1 px-4 rounded-r bg-green-500 text-white" onClick={() => multipleApproved(partiesToApprove)}>✔</button>
          </div>
        )}
      </div>
    </div>
  );
};

const QuotationItemRow = ({ item, selectedParty, selectRate }) => {
  const colors = ['bg-green-100 text-white', 'bg-red-100 text-white', 'bg-blue-100 text-white'];
  return (
    <div className="flex-col items-center sm:flex-row flex w-full gap-3 shadow border-b px-3 overflow-x-scroll">
      <div className="py-[2%] px-[1.3%] w-[100%] sm:w-[30%] md:w-[20%] text-center my-3 shadow-md border lg:min-h-[180px] rounded-lg">

        <h4 className="text-lg font-semibold">{item.item_title}</h4>
        <p className="text-gray-700"><strong>Category:</strong> {item.item_group_name}</p>
        <p className="text-gray-700 flex justify-center items-center"><strong>Required Qty:</strong> {item.required_qty}&nbsp;<MoveUp color='green' size={16} /></p>
        <p className="text-gray-700 flex justify-center items-center"><strong>Current Stock:</strong> {item.current_stock}&nbsp;<MoveDown color='red' size={16} /></p>
      </div>
      <div className='flex gap-3 justify-around w-[90%] sm:w-[80%]'>

        {item.purchases.map((purchase, index) => (
          <div key={index} className={`p-[2%] text-center my-3 shadow-md border rounded-lg ${selectedParty == purchase.party ? 'bg-slate-500' : "bg-slate-200"}`} >
            <p className="text-md"><strong> {purchase.party_name}</strong></p>
            <p className="text-sm"><strong>Discount:</strong> <span className='text-red-600'>₹{purchase.discount}%</span></p>
            <p className="text-sm"><strong>Total without GST:</strong> <span className='text-[#059212]'>₹{purchase.total_without_gst}</span></p>
            <p className="text-sm"><strong>Total with GST:</strong><span className='text-[#059212]'>₹{purchase.total_with_gst}</span></p>
            <p className="text-sm"><strong>Item Brand:</strong> {purchase.brand_name}</p>
            <button className={`w-10/12 mt-2 font-bold rounded ${purchase.status == "approved" ? "bg-green-500" : "bg-blue-500  "}  text-white`} disabled={purchase.status == "approved"} onClick={() => selectRate(purchase._id)}>₹{purchase.rate}</button>
          </div>
        ))}
      </div>
    </div>
  );
};

function QuotationComparisonDetail() {
  const { id } = useParams();

  const [quotationData, setQuotationData] = useState([]);
  const [parties, setParties] = useState([]);
  const [selectedParty, setSelectedParty] = useState();
  const [partiesToAprove, setPartiesToAprove] = useState([])

  const getQuotationData = async (groupId) => {
    try {
      const response = await api.get(`/purchase/get-quotation-comparison-detail/${groupId}`);
      setQuotationData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let newData = [];
    quotationData.forEach((data) => {
      data.purchases.map((item) => {
        if (item.party == selectedParty) {
          newData.push(item._id)
        }
      })
    })
    setPartiesToAprove(newData)

  }, [selectedParty])

  const multipleApproved = async (idsArray) => {
    // console.log(idsArray)
    try {
      const res = await api.patch(`/purchase/array-update-status-approved`, { idsArray })
      // console.log(res.data);
      getQuotationData(id)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    let parties = [];
    let mp = new Map();
    quotationData.forEach((data) => {
      data.purchases.forEach((purch) => {
        if (!mp.has(purch.party)) {
          mp.set(purch.party, {
            party_id: purch.party,
            party_name: purch.party_name,
            party_over_rate: purch.rate,
            total_with_gst: purch.total_with_gst,
            total_without_gst: purch.total_without_gst
          });
        } else {
          let partyData = mp.get(purch.party);
          partyData.party_over_rate += Number(purch.rate);
          partyData.total_with_gst += Number(purch.total_with_gst);
          partyData.total_without_gst += Number(purch.total_without_gst)
          mp.set(purch.party, partyData);
        }
      });
    });

    mp.forEach((value) => {
      parties.push(value);
    });

    setParties(parties);
  }, [quotationData]);

  useEffect(() => {
    getQuotationData(id);
  }, [id]);

  const selectRate = (_id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, approve it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed the action, proceed with the API call
        api.patch(`/purchase/update-status-approved/${_id}`)
          .then((res) => {
            // console.log(res.data)
            getQuotationData(id);
            Swal.fire(
              'Approved!',
              'The status has been updated.',
              'success'
            );
          })
          .catch(err => {
            console.log(err);
            Swal.fire(
              'Error!',
              'There was an issue updating the status.',
              'error'
            );
          });
      }
    });
  };




  return (
    <div className="container mx-auto p-4">
      <div className="h-10 px-2 flex justify-between items-center text-violet-500 font-bold rounded-md bg-[#fdfdfd] shadow mb-2">
        <div className="text-lg">Quotation Comparison Detail</div>
        <BackBtn />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3 mb-3">
        {parties.map((item, index) => {
          return (<PurchasePartyCard id={item.party_id} setSelectedParty={setSelectedParty} multipleApproved={multipleApproved} partiesToAprove={partiesToAprove} selectedParty={selectedParty} key={index} title={item.party_name} price={item.party_over_rate} otherCharges={`With GST: ${item.total_with_gst} | Without GST: ${item.total_without_gst}`} highlight={index == 1 ? true : false} />)
        })}
      </div>

      <div className="rounded-lg bg-[#FDFDFD]">
        {quotationData.map((item, index) => (
          <>
            <QuotationItemRow key={index} selectedParty={selectedParty} selectRate={selectRate} item={item} />
          </>
        ))}
      </div>
    </div>
  );
}

export default QuotationComparisonDetail;