import { useEffect, useState } from "react";
import api from "./Axios/api";
import { Trash2 } from "lucide-react";
import Select from "react-select";
import Swal from "sweetalert2";
import moment from "moment";
import BackBtn from "./common/BackBtn";
import { useNavigate } from "react-router-dom";

const AddIndentForm = () => {
    const [staffName, setStaffName] = useState([]);
    const [facLoc, setFacLoc] = useState([]);
    const [itemGroups, setItemGroups] = useState([]);
    const [dropItem, setDropItems] = useState([]);
    const [itemList, setItemList] = useState([]);
    const navigate = useNavigate();
    const [indent, setIndent] = useState({
        staff_name: "",
        fac_loc: "",
        date: moment().format("YYYY-MM-DD"),
        remark: "",
        items: [],
    });

    const [item, setItem] = useState({
        item_name: "",
        item_group: "",
        current_stock: "",
        required_qty: "",
        place_of_use: "",
        remark: ""
    });

    const alertMsg = async () => {
        await Swal.fire({
            title: 'Indent Added Successfully',
            icon: "success",
            html: `<button id="customButton" class="swal2-confirm swal2-styled">Ok</button>`,
            showConfirmButton: false,
            didOpen: () => {
                const customButton = document.getElementById('customButton');
                customButton.addEventListener('click', () => {
                    Swal.close();
                    navigate("/")
                });
            }
        })
    }

    const fillTheInputError = async (icon, description) => {
        await Swal.fire({
            title: description,
            icon: icon
        })
    }

    const finalSubmit = () => {
        if (!indent.staff_name || !indent.fac_loc || !indent.date || !indent.remark || itemList.length === 0) {
            fillTheInputError("warning", "Please fill in all item fields")
            return;
        }
        const modifiedItemList = itemList.map(item => {
            return {
                ...item,
                item_group: item.item_group.split("_")[0],
                item_name: item.item_name.split("_")[0]
            };
        });
        api.post(`/indent/add`, {
            "indent_date": indent.date,
            "indent_branch": indent.fac_loc.split("_")[0],
            "indent_by": indent.staff_name.split("_")[0],
            "indent_remark": indent.remark,
            "indentItem": modifiedItemList,
        })
            .then((res) => {
                console.log(res.data)
                clearItems();
                clearIndent();
                alertMsg();
                deleteAll();


            }).catch((err) => console.log(err))
    }

    const handleIndentInput = (e) => {
        const { name, value } = e.target;
        setIndent((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const clearItems = () => {
        setItem({
            item_name: "",
            item_group: "",
            current_stock: "",
            required_qty: "",
            place_of_use: "",
            remark: ""
        })
    }

    const getItemGroup = () => {
        api.get(`/purchase-item-category/get-name`)
            .then((res) => {
                const data = res.data;
                const newData = data.map((cat, index) => {
                    let value = cat._id + "_" + cat.itemcat_title;
                    return { value: value, label: cat.itemcat_title }
                })
                setItemGroups(newData)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // get staff Name 
    const staffNames = async () => {
        await api.get(`/staff/get-staff-name`)
            .then((res) => {
                const data = res.data;
                const newData = data.map((staff, index) => {
                    let value = staff._id + "_" + staff.staff_name
                    return { value: value, label: staff.staff_name }
                })
                setStaffName(newData)
            })
            .catch((err) => console.log(err))
    }

    // get the factory location
    const facLocHandler = async () => {
        await api.get(`/factory-location/get-factory-location`)
            .then((res) => {
                const data = res.data;
                const newData = data.map((staff, index) => {
                    let value = staff._id + "_" + staff.fectory_loca_name
                    return { value: value, label: staff.fectory_loca_name }
                })
                setFacLoc(newData)
            }).catch((err) => {
                console.log(err)
            })
    }

    const getGroupItems = async (catId) => {
        await api.get(`/purchase-item/get-item-name/${catId}`)
            .then((res) => {
                const data = res.data;
                const newData = data.map((item, index) => {
                    let value = item._id + "_" + item.item_title;
                    return { value: value, label: item.item_title }
                })
                setDropItems(newData)
            })
    }

    //  changes in the input of the 
    const handleItemChange = (e) => {
        const { name, value } = e.target;
        setItem((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const addItemList = () => {
        if (!item.item_name || !item.item_group || !item.current_stock || !item.required_qty || !item.place_of_use) {
            fillTheInputError("warning", "Please fill in all details");
            return;
        }
        setItemList((prev) => (
            [...prev, item]
        ));
        clearItems();
    }

    const deleteRow = (indexToDelete) => {
        const newArr = itemList.filter((item, index) => index !== indexToDelete)
        setItemList(newArr)
    }

    const clearIndent = () => {
        setIndent({
            staff_name: "",
            fac_loc: "",
            date: moment().format("YYYY-MM-DD"),
            remark: "",
            items: [],
        })
    }

    const deleteAll = () => {
        setItemList([])
    }

    const resetAll = () => {
        // reset indent item inputs and indent items status
        clearItems();
        clearIndent();
        deleteAll();
    }

    useEffect(() => {
        staffNames()
        facLocHandler()
        getItemGroup()
    }, [])

    useEffect(() => {
        getGroupItems(item.item_group.split('_')[0])
    }, [item.item_group])

    return (
        <div className='mt-3 flex flex-col w-full justify-center items-center h-full p-3'>
            <div className="w-11/12 h-10 px-2 flex justify-between items-center text-violet-500 font-bold rounded-md bg-[#fdfdfd] shadow">
                <div>Add Indent</div>
                <BackBtn path={"/"} />
            </div>

            <div className='w-11/12 flex flex-col mt-3 h-full rounded-md bg-[#fdfdfd] shadow overflow-y-auto p-3'>
                {/* for admin only */}

                <div className="flex sm:flex-row  flex-col gap-2 justify-center mt-2 items-center ">
                    <Select
                        options={staffName}
                        // defaultValue={inputs[index].partyName}
                        value={indent.staff_name}
                        placeholder={indent.staff_name.split("_")[1] || "Select Staff..."}
                        onChange={(e) => setIndent((indent) => ({ ...indent, staff_name: e.value }))}
                        styles={{
                            menuList: () => ({
                                overflowY: "scroll",
                                maxHeight: "200px",
                            }),
                            input: (baseStyles) => ({
                                ...baseStyles,
                                width: "180px"
                            }),
                            placeholder: (baseStyles) => (
                                {
                                    ...baseStyles,
                                    color: indent.staff_name ? "black" : "gray"
                                }
                            )
                        }}
                    />
                    <Select
                        options={facLoc}
                        // defaultValue={inputs[index].partyName}
                        value={indent.fac_loc}
                        placeholder={indent.fac_loc.split("_")[1] || "Select Branch..."}
                        onChange={(e) => setIndent((indent) => ({ ...indent, fac_loc: e.value }))}
                        styles={{
                            menuList: () => ({
                                overflowY: "scroll",
                                maxHeight: "200px",
                            }),
                            input: (baseStyles) => ({
                                ...baseStyles,
                                width: "180px"
                            }),
                            placeholder: (baseStyles) => (
                                {
                                    ...baseStyles,
                                    color: indent.fac_loc ? "black" : "gray"
                                }
                            )
                        }}
                    />
                    <input
                        type="date"
                        name="date"
                        id="date"
                        className="block  sm:w-[30%] w-11/12  rounded-md border-0 py-1.5 pl-7 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={indent.date}
                        onChange={handleIndentInput}
                    />
                    <input
                        type="text"
                        name="remark"
                        id="remark"
                        className="block  sm:w-[30%] w-11/12  rounded-md border-0 py-1.5 pl-7 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Remark"
                        value={indent.remark}
                        onChange={handleIndentInput}
                    />
                </div>

                <hr className="m-3 mb-6 w-full mx-auto" />

                {/* second layer of the form start here */}
                <div className="flex flex-col mx-auto gap-2 justify-center items-center">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-7 gap-2 w-full">

                        <Select
                            placeholder={item.item_group.split("_")[1] || "Select Category..."}
                            value={item.item_name}
                            options={itemGroups}
                            onChange={(e) => setItem((prev) => ({ ...prev, item_group: e.value }))}
                            styles={{
                                menuList: () => ({
                                    overflowY: "scroll",
                                    maxHeight: "200px",
                                }),
                                input: (baseStyles) => ({
                                    ...baseStyles,
                                    width: "180px"
                                }),
                                placeholder: (baseStyles) => (
                                    {
                                        ...baseStyles,
                                        color: item.item_group ? "black" : "gray",
                                    }
                                )
                            }}
                        />

                        <Select
                            options={dropItem}
                            placeholder={(item.item_name?.split("_")[1]?.length > 15 ? `${item.item_name.split("_")[1]?.slice(0, 20)}....` : item.item_name.split("_")[1]) || "Select Item..."}
                            value={item.item_name}
                            onChange={(e) => setItem((prev) => ({ ...prev, item_name: e.value }))}
                            styles={{
                                menuList: () => ({
                                    overflowY: "scroll",
                                    maxHeight: "200px",
                                }),
                                input: (baseStyles) => ({
                                    ...baseStyles,
                                    width: "180px"
                                }),
                                placeholder: (baseStyles) => (
                                    {
                                        ...baseStyles,
                                        color: item.item_name ? "black" : "gray"
                                    }
                                )
                            }}
                        />
                        <input
                            type="number"
                            name="current_stock"
                            id="current_stock"
                            placeholder="Curr Stock"
                            className=" rounded-md border-0 px-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={item.current_stock}
                            onChange={handleItemChange}
                        />
                        <input
                            type="number"
                            name="required_qty"
                            id="required_qty"
                            placeholder="Req Qty"
                            className=" rounded-md border-0 py-1.5 px-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={item.required_qty}
                            onChange={handleItemChange}
                        />
                        <input
                            type="text"
                            name="place_of_use"
                            id="place_of_use"
                            className=" rounded-md border-0 py-1.5 px-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="place of use"
                            value={item.place_of_use}
                            onChange={handleItemChange}
                        />
                        <input
                            type="text"
                            name="remark"
                            id="remark"
                            className=" rounded-md border-0 px-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Remark"
                            value={item.remark}
                            onChange={handleItemChange}
                        />
                        <div className="flex justify-center items-center">
                            <div className="rounded bg-violet-600 hover:bg-violet-700 text-white font-bold p-1.5 px-3 cursor-pointer" onClick={addItemList}>
                                <span>+ ADD</span>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    itemList.length > 0 ?
                        <>
                            <div className='w-full m-auto flex flex-col justify-center items-center rounded bg-[#fdfdfdfd] border'>
                                <div className='font-bold text-lg'>
                                    indent item
                                </div>
                                <div className=" container mx-auto pt-4">
                                    <div className='overflow-x-auto'>
                                        <table className="min-w-full bg-white border border-gray-200">
                                            <thead>
                                                <tr>
                                                    <th className="px-4 py-2 border">item name</th>
                                                    <th className="px-4 py-2 border">item group</th>
                                                    <th className="px-4 py-2 border">current stock</th>
                                                    <th className="px-4 py-2 border">required qty</th>
                                                    <th className="px-4 py-2 border">place of use</th>
                                                    <th className="px-4 py-2 border">remark</th>
                                                    <th className="px-4 py-2 border flex justify-evenly">Delete <Trash2 className="transition-colors text-white ease-in bg-red-600 hover:bg-red-700 p-1 rounded shadow cursor-pointer"
                                                        onClick={deleteAll} />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    itemList.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className={`px-4 py-2 border text-center`}>
                                                                    {item.item_name.split('_')[1]}
                                                                </td>
                                                                <td className={`px-4 py-2 border text-center`}>
                                                                    {item.item_group.split('_')[1]}
                                                                </td>
                                                                <td className={`px-4 py-2 border text-center`}>
                                                                    {item.current_stock}
                                                                </td>
                                                                <td className={`px-4 py-2 border text-center`}>
                                                                    {item.required_qty}
                                                                </td>
                                                                <td className={`px-4 py-2 border text-center`}>
                                                                    {item.place_of_use}
                                                                </td>
                                                                <td className={`px-4 py-2 border text-center`}>
                                                                    {item.remark}
                                                                </td>
                                                                <td className={`px-4 py-2 border flex justify-center`}>
                                                                    <div className="transition-colors ease-in bg-red-600 hover:bg-red-700 p-1 rounded shadow cursor-pointer"
                                                                        onClick={() => deleteRow(index)}
                                                                    >
                                                                        <Trash2 className="text-center" color="white" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <div className="w-full sm:w-1/2 lg:w-[10%] bg-[#FF8080] flex justify-center items-center rounded-md py-1.5 mt-2 lg:mt-0 cursor-pointer h-7" onClick={finalSubmit} >
                                Submit
                            </div> */}
                            </div>
                            <div className="flex justify-center items-center gap-3">
                                <div className="rounded bg-red-600 hover:bg-red-700 text-white font-bold p-1 w-[20%] cursor-pointer text-center" onClick={resetAll}>
                                    <span>Reset</span>
                                </div>
                                <div className="rounded bg-green-600 hover:bg-green-700 text-white font-bold p-1 w-[20%] cursor-pointer text-center" onClick={finalSubmit}>
                                    <span>Submit</span>
                                </div>
                            </div>
                        </>
                        :
                        ""
                }
            </div>
        </div>
    )
}

export default AddIndentForm