import React, { useEffect, useState } from 'react';
import QuotationComparisonTable from './DataTables/QuotationComparisonTable';
import api from './Axios/api';

const QuoteComparison = () => {
    const [data, setData] = useState([]);
    const [history, setHistory] = useState(false);

    const quotations = async () => {
        try {
            const response = await api.get(`/purchase/get-quotation-comparison-list${history ? '?type=history' : ""}`);
            setData(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        quotations();
    }, [history])

    return (
        <div className='px-5 flex flex-col items-center'>
            <div className="w-full h-10 px-2 flex justify-between items-center text-violet-500 font-bold rounded-md bg-[#fdfdfd] shadow my-2">
                <div className="text-lg">{history ? "Quotation Comparison History" : "Quotation Comparison List"}</div>
                <div onClick={() => { setHistory(!history) }} className='text-white font-bold rounded-md px-3 bg-violet-600 hover:bg-violet-700 hover:ease-in shadow border flex items-center cursor-pointer transition-colors'>
                    {history ? "back" : "History"}
                </div>
            </div>
            <div className='w-full min-h-[500px] rounded-md shadow bg-[#fdfdfd] '>
                <QuotationComparisonTable data={data} />
            </div>
        </div>
    )
}

export default QuoteComparison;