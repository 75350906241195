import React from "react";
import SideNav from "./components/common/SideNav";
import TopNav from "./components/common/TopNav";
import { Routes, Route } from "react-router-dom";
import About from './components/About';
import Home from './components/Home';
import AddIndentForm from './components/AddIndentForm';
import QuoteComparison from "./components/QuoteComparison";
import CreatePOForm from "./components/CreatePOForm";
import ApprovalList from "./components/ApprovalList";
import RateComparison from "./components/RateComparison";
import AddPurchaseDetails from "./components/AddPurchaseDetails";
import CreateReceiptNoteFrom from "./components/CreateReceiptNoteFrom";
import ReceiptNoteReport from "./components/ReceiptNoteReport ";
import CreatedPOList from "./components/CreatedPOList";
import Dashboard from "./components/Dashboard";
import Login from "./components/common/Login";
import AuthRouter from "./components/AuthRouter";
import { useSelector } from "react-redux";
import IndentEdit from "./components/IndentEdit";
import PurchaseEntry from "./components/PurchaseEntry";
import PurchasePayment from "./components/PurchasePayment";
import ProductionForm from "./components/ProductionForm";
import ConsumptionForm from "./components/ConsumptionForm";
import IndentDetails from "./components/IndentDetails";
import QuotationComparisonDetail from "./components/QuotationComparisonDetail";

const App = () => {

  const currentUser = useSelector((state) => state.user.staff_name)

  return (
    <main style={{ display: "flex", background: "#EDEEF7" }}>
      {currentUser && <SideNav />}
      <div className="flex flex-1 flex-col h-screen w-full overflow-y-scroll main-content">
        {currentUser && <TopNav />}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<AuthRouter />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path='/about' element={<About />} />
            <Route index element={<Home />} />
            <Route path='/indentForm' element={<AddIndentForm />} />
            <Route path="/details/:id" element={<IndentDetails />} />
            <Route path='/add-purchase-details/' element={<AddPurchaseDetails />} />
            <Route path='/quote-comparison' element={<QuoteComparison />} />
            <Route path='/quote-comparison-detail/:id' element={<QuotationComparisonDetail />} />
            <Route path='/approval-list' element={<ApprovalList />} />
            <Route path='/create-po-form' element={<CreatePOForm />} />
            <Route path='/created-po-list' element={<CreatedPOList />} />
            <Route path='/rate-comparison' element={<RateComparison />} />
            <Route path='/receipt-note-report' element={<ReceiptNoteReport />} />
            <Route path='/create-receipt-form' element={<CreateReceiptNoteFrom />} />
            <Route path="/edit/:id" element={<IndentEdit />} />
            <Route path='/purchase-entry' element={<PurchaseEntry />} />
            <Route path='/purchase-payment' element={<PurchasePayment />} />
            <Route path='/consumption-form' element={<ConsumptionForm />} />
            <Route path='/production-form' element={<ProductionForm />} />
          </Route>

        </Routes>
      </div>
    </main>
  );
};

export default App;
