import React, { useEffect, useState } from 'react'
import IndentCard from './IndentCard'
import HeadTag from './HeadTag'
import DataTable from './DataTables/DataTable';
import api from "./Axios/api"

const Home = () => {

    const [data, setData] = useState([]);

    const getIndentData = async () => {
        await api.get('/indent/get/')
            .then((res) => setData(res.data))
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        getIndentData()
    }, [])

    return (
        <div className='flex-1 mt-5 w-full justify-center items-center '>
            <div className='flex w-full flex-col justify-center items-center'>
                <div className='flex w-11/12 justify-between items-center flex-wrap'>
                    <IndentCard name="Total" count={12} color='#CAF4FF' />
                    <IndentCard name="Progress" count={8} color='#FEFFD2' />
                    <IndentCard name="Open" count={6} color='#95D2B3' />
                    <IndentCard name="Closed" count={4} color='#FFC5C5' />
                </div>
                <HeadTag tagName='Indent List' to='/indentForm' type='new' buttonName="Add Indent" />

                <div className=' w-11/12  my-2 rounded-md shadow bg-[#fdfdfd] '>
                    <DataTable data={data} />
                </div>
            </div>
        </div>
    )
}

export default Home 