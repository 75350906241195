import { MoveLeft } from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function BackBtn() {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
        // if (window.history.length > 1) {
        //     navigate(-1);
        // } else {
        //     navigate('/'); // Navigate to a default page if no history
        // }
    };
    return (
        <div
            className="text-white font-bold rounded-md px-3 bg-violet-600 hover:bg-violet-700 hover:ease-in shadow border flex items-center cursor-pointer transition-colors"
            onClick={handleBackClick}
        >
            <MoveLeft size={20} />
            <div>Back</div>
        </div>
    )
}

export default BackBtn