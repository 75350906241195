import React, { useEffect } from "react";
import api from "../Axios/api";

const ReceiptEditData = async ({ receiptId, setFormState, setPoNoDropdown, setReceiptImgUrl, setOrderDateDropDown, setReceiptItems }) => {
    try {
        const res = await api.get(`/receipt-note/get-print-detail/${receiptId}`);
        const data = res.data[0];
        setReceiptImgUrl({
            receipt_item_id: data._id,
            receipt_challan_img: data.receipt_challan_img,
            receipt_weight_slip_img: data.receipt_weight_slip_img,
            receipt_item_img: data.receipt_item_img,
            receipt_invoice_img: data.receipt_invoice_img,
            receipt_quality_img: data.receipt_quality_img,
        })
        setFormState({
            entry_date: data.entry_date?.split('T')[0],
            receipt_no: data.receipt_no,
            staff_id: data.staff_id,
            party_id: data.party_id,
            po_number: data.po_number,
            po_id: data.po_id,
            purchase_ledger: data.purchase_ledger,
            order_date: data.order_date,
            receipt_status: data.receipt_status,
            terms_of_payment: data.terms_of_payment,
            other_references: data.other_references,
            terms_of_delivery: data.terms_of_delivery,
            receipt_doc_no: data.receipt_doc_no,
            dispatched_through: data.dispatched_through,
            destination: data.destination,
            carrier_name: data.carrier_name,
            bill_of_lading: data.bill_of_lading,
            motor_vehicle_no: data.motor_vehicle_no,
            insurance_policy_no: data.insurance_policy_no,
            contact_person: data.contact_person,
            vehicle_no: data.vehicle_no,
            driver_mobile_no: data.driver_mobile_no,
            challan_no: data.challan_no,
            weight_slip_no: data.weight_slip_no,
            weight_slip_date: data.weight_slip_date?.split('T')[0],
            fright_advice_no: data.fright_advice_no,
            stores_remarks: data.stores_remarks,
            challan_date: data.challan_date?.split('T')[0],
            gate_entry_no: data.gate_entry_no,
            fright_rs: data.fright_rs,

            receipt_weight_kg: data.receipt_weight_kg,
            receipt_narration: data.receipt_narration,
            receipt_total_without_gst: data.receipt_total_without_gst,
            receipt_total_with_gst: data.receipt_total_with_gst,

            receipt_charge_name1: data.receipt_charge_name1,
            receipt_charge_amount1: data.receipt_charge_amount1,
            receipt_charge_gst1: data.receipt_charge_gst1,
            receipt_total_with_charge1: data.receipt_total_with_charge1,

            receipt_charge_name2: data.receipt_charge_name2,
            receipt_charge_amount2: data.receipt_charge_amount2,
            receipt_charge_gst2: data.receipt_charge_gst2,
            receipt_total_with_charge2: data.receipt_total_with_charge2,

            receipt_total_amount: data.receipt_total_amount,
        });
        const newData1 = data.po_number.map((PoNum, index) => ({
            value: data.po_id[index],
            label: PoNum,
        }));
        const orderDateDropDown = data.order_date.map(date => ({ value: date, label: date }));
        const receiptItems = data.receipt_item_info.map(item => ({
            _id: item._id,
            purchase_id: item.purchase_id,
            receipt_item_id: item.receipt_item_id,
            receipt_item_qty: item.receipt_item_qty,
            receipt_item_rem_qty: item.receipt_item_rem_qty,
            receipt_item_rate: item.receipt_item_rate,
            receipt_item_disc: item.receipt_item_disc,
            receipt_item_extra_gst: item.receipt_item_extra_gst,
            receipt_item_total_without_gst: item.receipt_item_total_without_gst,
            receipt_item_total_with_gst: item.receipt_item_total_with_gst,
            receipt_item_remark: item.receipt_item_remark,
        }));
        setPoNoDropdown([{ value: null, label: "Case" }, ...newData1]);
        setOrderDateDropDown(orderDateDropDown);
        setReceiptItems(receiptItems);
    } catch (error) {
        console.error(error);
    }
}

export default ReceiptEditData