import { Search } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { socket } from './socket';
import { RotatingLines } from 'react-loader-spinner';

function SearchBar({ searchString }) {
    const [searchData, setSearchData] = useState([]);
    const [loading, setLoading] = useState(true);
    console.log(loading);

    const handleSearch = () => {
        socket.emit('search-items', { searchString }, (results) => {
            if (results.error) {
                console.error(results.error);
            } else {
                setLoading(false);
                setSearchData(results);
            }
        });
    }

    useEffect(() => {
        setLoading(true);
        socket.connect();
        handleSearch();

        return () => {
            socket.disconnect();
        };
    }, [searchString]);

    return (
        <div className="border-2 min-h-10 max-h-[250px] rounded overflow-auto">
            {!loading ? (
                searchData.length > 0 ? (
                    <table className="min-w-full bg-white rounded shadow">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 border-b">Item Name</th>
                                <th className="py-2 px-4 border-b">Party Name</th>
                                <th className="py-2 px-4 border-b">Rate</th>
                                <th className="py-2 px-4 border-b">Unit</th>
                                <th className="py-2 px-4 border-b">Indent Date</th>
                                <th className="py-2 px-4 border-b">Indent Branch</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchData.map((item, index) => (
                                <tr key={index} className="border-b hover:bg-gray-100" onClick={() => alert("This is currently working... rowId: " + item._id)}>
                                    <td className="py-2 px-4">{item.item_name || 'N/A'}</td>
                                    <td className="py-2 px-4">{item.party_name || 'N/A'}</td>
                                    <td className="py-2 px-4">{item.rate !== undefined ? item.rate : 'N/A'}</td>
                                    <td className="py-2 px-4">{item.item_unit || 'N/A'}</td>
                                    <td className="py-2 px-4">{item.indent_date ? new Date(item.indent_date).toLocaleDateString() : 'N/A'}</td>
                                    <td className="py-2 px-4">{item.indent_branch || 'N/A'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center py-2 border-2 shadow">No search results found</p>
                )
            ) : (
                <div className="flex justify-center items-center h-full">
                    <RotatingLines
                        visible={true}
                        height="40"
                        width="40"
                        color="grey"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                    />
                </div>
            )}
        </div>
    );
}
export default SearchBar;
