import React from 'react'

const IndentCard = ({ name, count, color }) => {

    return (
        <div className={`flex flex-col   justify-center items-center md:w-1/5 lg:h-32  w-1/2  h-20 rounded-md shadow`} style={{ backgroundColor: color }} >
            {count}
            <span>{name}</span>
        </div>
    )
}

export default IndentCard