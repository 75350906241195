import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Input, Slide } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import React, { useMemo, useState } from 'react'
const data = [
  {
    CheckBox:'CheckBox',
    // SNo: 1,
    Date:"",
    GRNNo:"",
    GRNDATE:"",
    
    PARTY:"",
     PONO:"", 
    ACTION: 'Jane',  
  },
  {
    CheckBox:'CheckBox',
    // SNo: 2,
    Date:"",
    GRNNo:"",
    GRNDATE:"",
    
    PARTY:"",
     PONO:"", 
    ACTION: 'Jane',  
  },
  {
    CheckBox:'CheckBox',
    // SNo: 3,
    Date:"",
    GRNNo:"",
    GRNDATE:"",
    PARTY:"",
     PONO:"", 
    ACTION: 'Joe',  
  },
  {
    CheckBox:'CheckBox',
    // SNo: 4,
    Date:"",
    GRNNo:"",
    GRNDATE:"",
    PARTY:"",
     PONO:"", 
    ACTION: 'Kevin',
  },
  {
    CheckBox:'CheckBox',
    // SNo: 5,
    Date:"",
    GRNNo:"",
    GRNDATE:"",
    PARTY:"",
     PONO:"", 
    ACTION:'Joshua',
      
  },
];
function PurchasePayment() {
  const [checked, setChecked] = useState(new Array(data.length).fill(false));

  const handleCheckboxChange = (index) => {
    setChecked((prevChecked) =>
      prevChecked.map((item, idx) => (idx === index ? !item : item))
    );
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'CheckBox',
        header: '',
        size: 200,
        Cell: ({ row }) => (
          <div className="flex items-center">
            <Checkbox
              className="form-checkbox h-5 w-5 text-blue-600"
              checked={checked[row.index]}
              onChange={() => handleCheckboxChange(row.index)}
            />
          </div>
        ),
      },
      {
        accessorKey: 'name.Date',
        header: 'Date',
        size: 150,
      },
      {
        accessorKey: 'name.GRNNo',
        header: 'GRN NO.',
        size: 150,
      },
      {
        accessorKey: 'name.GRNDATE',
        header: 'GRN DATE',
        size: 150,
      },
   
      
      {
        accessorKey: 'name.PARTY',
        header: 'PARTY',
        size: 150,
      },
      {
        accessorKey: 'name.PONO',
        header: 'PO No.',
        size: 150,
      },
      {
        accessorKey: 'name.Status',
        header: 'QUANTITY',
        size: 150,
      },
      {
        accessorKey: 'name.ACTION',
        header: 'ACTION',
        size: 150,
        Cell: ({ row }) => (
          <div className="flex items-center">
            <Button variant="outlined" onClick={handleClickOpen}>
              Purchase
            </Button>
          </div>
        ),
      },
    ],
    [checked]
  );

  const table = useMaterialReactTable({
    columns,
    data,
  });
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const [formState, setFormState] = useState({
    paid_date_in: "",
    pay_trans_num: "",
   
    pur_total_value: "",
    pur_remark: "",
    pur_credit_debit_value:"0",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(formState);

    // api
    //   .post("/receipt-note/add", { formState })
    //   .then((response) => {
    //     // handle the response
    //     console.log("Form submission successful:", response.data);
    //   })
    //   .catch((error) => {
    //     // handle the error
    //     console.error("There was an error submitting the form:", error);
    //   });
  };
  return (
    <div className="flex-1 px-5 w-full justify-center items-center">
      <React.Fragment>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >

        
          <DialogTitle className="text-center">{"Are you sure?"}</DialogTitle>
          <DialogTitle className='text-center'>{"Payment ₹ 23,565.58 Amount!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="grid grid-cols-2 md:grid-cols-2 gap-6">
                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-700">
                  Bank Payment Date<span className="text-red-500">*</span>
                  </label>
                  <Input
                   type="date"
                    name="paid_date_in"
                    value={formState.paid_date_in}
                    onChange={handleChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Invoice Date"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-700">
                    Transaction Id* 
                    <span className="text-red-500">*</span>
                  </label>
                  <Input
                    type="text"
                    name="pay_trans_num"
                    value={formState.pay_trans_num}
                    onChange={handleChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Party Invoice No. "
                  />
                </div>
              </div>
              
            
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit}>Submit</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    <div>
      <div className="bg-[#fdfdfd] border mt-4 p-2 flex w-full justify-between items-center rounded-md shadow">
        <div className="flex rounded-md items-center w-1/2">
          <h2 className="font-bold text-[#7286D3]">Purchase Payment</h2>
        </div>
        {/* <div className="w-50 border p-2 bg-slate-300 rounded-lg font-bold ">
          <Link to="/create-po-form" title="Create PO">
             Create PO
          </Link>
        </div> */}
      </div>
    </div>
    <div className="w-full min-h-[500px] my-2 rounded-md shadow bg-[#fdfdfd]">
      <MaterialReactTable table={table} />
    </div>
  </div>
  )
}

export default PurchasePayment
