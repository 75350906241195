import React, { useState } from 'react'
import { Search, AlignJustify, X, SquareKanban, ShoppingBasket, GitCompare, ShoppingBag, PackageCheck, ReceiptText, LayoutDashboard } from 'lucide-react';
import { Link } from 'react-router-dom';
import SearchBar from '../socket/SearchBar';
const TopNav = () => {
    const [searchString, setSearchString] = useState('');
    const [isSearch, setIsSearch] = useState(false);
    const [show, setShow] = useState(false);


    const handleBlur = () => {
        if (searchString.length === 0) {
            setIsSearch(false)
        }
    }

    return (
        <div className='flex flex-col w-full mx-auto px-5  relative'>
            <div className=' sm:block flex-1 relative'>
                <div className='p-3  bg-[#fdfdfd] flex mt-4 items-center border shadow-md justify-between rounded-lg '>
                    <div className='sm:hidden block'>
                        {
                            show ?
                                <X onClick={() => setShow((curr) => !curr)} />
                                : <AlignJustify className='ham' onClick={() => setShow((curr) => !curr)} />
                        }
                    </div>
                    <div className='relative border-b  border-black  lg:w-96 sm:52'>
                        <input
                            type='text'
                            className='outline-none lg:w-80 sm:w-48 w-28 relative'
                            placeholder='Search. . .'
                            onChange={(e) => setSearchString(e.target.value)}
                            onFocus={() => setIsSearch(true)}
                            onBlur={(e) => handleBlur()}
                        />
                        <Search className='absolute top-0 right-0' />
                    </div>
                    <div className='flex w-1/2 lg:w-80 justify-around items-center'>
                        <h2 className='hidden sm:block'>
                            PolyBond Insulation
                        </h2>
                        <img className="inline-block h-9 w-9 rounded-full ring-2 ring-white" src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                    </div>
                </div>
            </div>

            {/* Search Option */}
            {isSearch && <SearchBar searchString={searchString} />}

            <div className={` ${show ? 'block' : 'hidden'} border-b-2 transition-all w-[89%] min-h-24  bg-[#fdfdfd] rounded-b-md absolute top-[85%] z-10`}>
                <div className='flex flex-col'>
                    <ul className='flex flex-col bg-[#EEF5FF] flex-wrap gap-3 px-3.5 py-2 items-start justify-center shadow'>
                        <Link className='flex hover:bg-[#615EFC] w-full rounded p-2 bg-[#EEF5FF]'><LayoutDashboard size={20} /> Dashbord</Link>
                        <Link className='flex hover:bg-[#615EFC] p-2 w-full rounded'><SquareKanban size={20} /> Indent </Link>
                        <Link to='/indentToPurchase' className='flex hover:bg-[#615EFC] p-2 w-full rounded'><ShoppingBasket size={20} />Item to Purchase</Link>
                        <Link className='flex hover:bg-[#615EFC] p-2 w-full rounded'><GitCompare size={20} />Quotation Comparison</Link>
                        <Link className='flex hover:bg-[#615EFC] p-2 w-full rounded'><ShoppingBag size={20} />Create PO</Link>
                        <Link className='flex hover:bg-[#615EFC] p-2 w-full rounded'><PackageCheck size={20} />Created Po</Link>
                        <Link className='flex hover:bg-[#615EFC] p-2 w-full rounded'><ReceiptText size={20} />Receipt Note</Link>
                        <Link className='flex hover:bg-[#615EFC] p-2 w-full rounded'><ReceiptText size={20} />Purchase Entry</Link>
                        <Link className='flex hover:bg-[#615EFC] p-2 w-full rounded'><ReceiptText size={20} />Purchase Payment</Link>
                        <Link className='flex hover:bg-[#615EFC] p-2 w-full rounded'><ReceiptText size={20} />Consumption Form</Link>
                        <Link className='flex hover:bg-[#615EFC] p-2 w-full rounded'><ReceiptText size={20} />Production Form</Link>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default TopNav