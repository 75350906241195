import { useEffect, useState } from "react";
import api from "./Axios/api";
import { Trash2 } from "lucide-react";
import Select from "react-select";
import Swal from "sweetalert2";

const ConsumptionForm = () => {
  const [itemGroups, setItemGroups] = useState([]);
  const [dropItem, setDropItems] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [indent, setIndent] = useState({
    conDate: "",
    conVoucherNo: "",
    items: [],
  });

  const [item, setItem] = useState({
    item_name: "",
    item_group: "",
    conQuantity: "",
    conRate: "",
    conAmount: "",
    conVoucherNo: "",
  });

  // const alertMsg = async () => {
  //   await Swal.fire({
  //     title: "Indent Added Successfully",
  //     icon: "success",
  //   });
  // };

  const fillTheInputError = async (icon, description) => {
    await Swal.fire({
      title: description,
      icon: icon,
    });
  };

  const finalSubmit = () => {
    alert("Hellow Ji");
    // if (|| !indent.conDate || !indent.conVoucherNo || itemList.length === 0) {

    //     fillTheInputError("warning", "Please fill in all item fields")
    //     return;
    // }

    // const modifiedItemList = itemList.map(item => {
    //     return {
    //         ...item,
    //         item_group: item.item_group.split("_")[0],
    //         item_name: item.item_name.split("_")[0]
    //     };
    // });

    // api.post(`/indent/add`, {
    //     "indent_date": indent.conDate,
    //     "indent_remark": indent.conVoucherNo,
    //     "indentItem": modifiedItemList,
    // })
    //     .then((res) => {
    //         console.log(res.data)
    //         clearItems();
    //         clearIndent();
    //         alertMsg();
    //         deleteAll();
    //     }).catch((err) => console.log(err))
  };

  const handleIndentInput = (e) => {
    const { name, value } = e.target;

    setIndent((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clearItems = () => {
    setItem({
      item_name: "",
      item_group: "",
      conQuantity: "",
      conRate: "",
      conAmount: "",
      conVoucherNo: "",
    });
  };
  

  const getItemGroup = () => {
    api
      .get(`/purchase-item-category/get-name`)
      .then((res) => {
        const data = res.data;
        const newData = data.map((cat, index) => {
          let value = cat._id + "_" + cat.itemcat_title;
          return { value: value, label: cat.itemcat_title };
        });
        setItemGroups(newData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGroupItems = async (catId) => {
    await api.get(`/purchase-item/get-item-name/${catId}`).then((res) => {
      const data = res.data;

      const newData = data.map((item, index) => {
        let value = item._id + "_" + item.item_title;
        return { value: value, label: item.item_title };
      });
      setDropItems(newData);
    });
  };

  //  changes in the input of the
  const handleItemChange = (e) => {
    const { name, value } = e.target;

    setItem((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addItemList = () => {
    if (
      !item.item_name ||
      !item.item_group ||
      !item.conQuantity ||
      !item.conRate ||
      !item.conAmount
    ) {
      fillTheInputError("warning", "Please fill in all details");
      return;
    }

    setItemList((prev) => [...prev, item]);
    clearItems();
  };

  const deleteRow = (indexToDelete) => {
    const newArr = itemList.filter((item, index) => index !== indexToDelete);
    setItemList(newArr);
  };

  const deleteAll = () => {
    setItemList([]);
  };

  useEffect(() => {
    getItemGroup();
  }, []);

  useEffect(() => {
    getGroupItems(item.item_group.split("_")[0]);
  }, [item.item_group]);
  return (
    <div className="mt-3 flex flex-col w-full justify-center items-center h-full p-3">
      <div className="w-11/12 h-10 flex justify-center items-center text-[#7286D3] font-bold rounded-md bg-[#fdfdfd]">
        Consumption Form
      </div>
      <div className="w-11/12 flex flex-col mt-3 h-full rounded-md bg-[#fdfdfd] shadow overflow-y-auto">
        {/* for admin only */}
        <div className="flex sm:flex-row  flex-col gap-2  px-10 mt-4 items-center ">
          <input
            type="date"
            name="conDate"
            id="conDate"
            className="block  sm:w-[30%] w-11/12  rounded-md border-0 py-1.5 pl-7 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={indent.conDate}
            onChange={handleIndentInput}
          />
          <input
            type="text"
            name="conVoucherNo"
            id="conVoucherNo"
            className="block  sm:w-[30%] w-11/12  rounded-md border-0 py-1.5 pl-7 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Voucher No."
            value={indent.conVoucherNo}
            onChange={handleIndentInput}
          />
        </div>

        <hr className="mt-2 w-11/12 mx-auto" />

        {/* second layer of the form start here */}
        <div className="flex flex-col mt-3 w-11/12 mx-auto gap-2 ">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2 w-full">
            <Select
              placeholder={item.item_group.split("_")[1]}
              value={item.item_name}
              options={itemGroups}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, item_group: e.value }))
              }
              styles={{
                menuList: () => ({
                  overflowY: "scroll",
                  maxHeight: "120px",
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  width: "180px",
                }),
                placeholder: (baseStyles) => ({
                  ...baseStyles,
                  color: "black",
                }),
              }}
            />

            <Select
              options={dropItem}
              placeholder={
                item.item_name?.split("_")[1]?.length > 15
                  ? `${item.item_name.split("_")[1]?.slice(0, 20)}....`
                  : item.item_name.split("_")[1]
              }
              value={item.item_name}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, item_name: e.value }))
              }
              styles={{
                menuList: () => ({
                  overflowY: "scroll",
                  maxHeight: "120px",
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  width: "180px",
                }),
                placeholder: (baseStyles) => ({
                  ...baseStyles,
                  color: "black",
                }),
              }}
            />
            <input
              type="number"
              name="conQuantity"
              id="conQuantity"
              placeholder="Curr Stock"
              className=" w-full rounded-md border-0 py-1.5 pl-7 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              value={item.conQuantity}
              onChange={handleItemChange}
            />
            <input
              type="number"
              name="conRate"
              id="conRate"
              placeholder="Req Qty"
              className=" w-full rounded-md border-0 py-1.5 pl-7 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              value={item.conRate}
              onChange={handleItemChange}
            />
            <input
              type="text"
              name="conAmount"
              id="conAmount"
              className="w-full rounded-md border-0 py-1.5 pl-7 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="place of use"
              value={item.conAmount}
              onChange={handleItemChange}
            />
            <div className="w-full flex justify-around mt-3">
              <div
                className="w-full border-current   bg-[#7E8EF1] flex justify-center items-center rounded-md   cursor-pointer "
                onClick={addItemList}
              >
                + ADD
              </div>
              <div
                className="w-full   bg-[#FF8080] flex justify-center items-center rounded-md  cursor-pointer "
                onClick={finalSubmit}
              >
                Submit
              </div>
            </div>
          </div>
        </div>

        {itemList.length > 0 ? (
          <div className="w-11/12 m-auto flex flex-col justify-center items-center rounded bg-[#fdfdfdfd]">
            <div className="font-bold text-lg">indent item</div>

            <div className=" container mx-auto p-4">
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border">item name</th>
                      <th className="px-4 py-2 border">item group</th>
                      <th className="px-4 py-2 border">current stock</th>
                      <th className="px-4 py-2 border">required qty</th>
                      <th className="px-4 py-2 border">place of use</th>
                      <th className="px-4 py-2 border">conVoucherNo</th>
                      <th className="px-4 py-2 border flex justify-evenly">
                        Delete{" "}
                        <Trash2
                          className="transition-colors ease-in bg-[#A0153E] p-1 rounded shadow hover:bg-[#FF204E] cursor-pointer"
                          onClick={deleteAll}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className={`px-4 py-2 border text-center`}>
                            {item.item_name.split("_")[1]}
                          </td>
                          <td className={`px-4 py-2 border text-center`}>
                            {item.item_group.split("_")[1]}
                          </td>
                          <td className={`px-4 py-2 border text-center`}>
                            {item.conQuantity}
                          </td>
                          <td className={`px-4 py-2 border text-center`}>
                            {item.conRate}
                          </td>
                          <td className={`px-4 py-2 border text-center`}>
                            {item.conAmount}
                          </td>
                          <td className={`px-4 py-2 border text-center`}>
                            {item.conVoucherNo}
                          </td>
                          <td
                            className={`px-4 py-2 border flex justify-center`}
                          >
                            <div
                              className="transition-colors ease-in bg-[#A0153E] p-1 rounded shadow hover:bg-[#FF204E] cursor-pointer"
                              onClick={() => deleteRow(index)}
                            >
                              <Trash2 className="text-center" color="white" />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ConsumptionForm;
