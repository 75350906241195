import React from "react";
import { useMemo } from 'react';
import { Link } from "react-router-dom";
import { Printer } from "lucide-react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
const data = [
  {
    SNo: 1,
    name: {
      ITEM: 'John',
      QUANTITY: 'Doe',
    },

  },
  {
    SNo: 2,
    name: {
      ITEM: 'Jane',
      QUANTITY: 'Doe',
    },

  },
  {
    SNo: 3,
    name: {
      ITEM: 'Joe',
      QUANTITY: 'Doe',
    },
  },
  {
    SNo: 4,
    name: {
      ITEM: 'Kevin',
      QUANTITY: 'Vandy',
    },

  },
  {
    SNo: 5,
    name: {
      ITEM: 'Joshua',
      QUANTITY: 'Rolluffs',
    },

  },
];

const RateComparison = () => {
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'SNo', //normal accessorKey
        header: 'S No.',
        size: 200,
      },
      {
        accessorKey: 'name.ITEM', //access nested data with dot notation
        header: 'ITEM NAME',
        size: 150,
      },
      {
        accessorKey: 'name.QUANTITY',
        header: 'QUANTITY',
        size: 150,
      },


      // {
      //     accessorKey: 'city',
      //     header: 'City',
      //     size: 150,
      // },
      // {
      //     accessorKey: 'state',
      //     header: 'State',
      //     size: 150,
      // },
      // {
      //     accessorKey: 'action',
      //     header: 'Action',
      //     size: 150,
      // },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });
  return (
    <div className="flex-1  px-5 w-full  justify-center items-center">
      <div>
        <div className=" bg-[#fdfdfd] border mt-4 p-2 flex w-full justify-between items-center rounded-md  shadow">
          <div className=" flex rounded-md  items-center  w-1/2 ">
            <h2 className="font-bold text-[#7286D3]">Rate Comparison</h2>
          </div>
          <div className="w-5  items-right">
            <Link
              to={"/indentForm"}
              title="Print"
            //   className="bg-[#7E8EF1] w-full transition-colors rounded-md shadow-sm p-2 hover:bg-[#615EFC] hover:ease-in text-[#ffff] text-xs"
            >
              <Printer size={20} color="black" />
            </Link>
          </div>
        </div>
      </div>
      <div className=" w-full min-h-[500px] my-2 rounded-md shadow bg-[#fdfdfd] ">
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
};

export default RateComparison;
