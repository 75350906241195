import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import api from '../Axios/api';
import { FileText, Pencil } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";

const ReceiptNoteReport = () => {
  const navigation = useNavigate();
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      const res = await api.get(`/receipt-note/get`)
      setData(res.data);
      // console.log(res.data);
    } catch (error) {
      console.log(error)
    }
  }

  const handlePrint = (cell) => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = `https://polybonderps.site/purchase-print/receipt-print.php`;
    form.target = '_blank';

    const receiptId = document.createElement('input');
    receiptId.type = 'hidden';
    receiptId.name = 'receiptId';
    receiptId.value = cell.getValue();
    form.appendChild(receiptId);

    const dataInput = document.createElement('input');
    dataInput.type = 'hidden';
    dataInput.name = 'token';
    dataInput.value = JSON.stringify(Cookies.get('access_token'));
    form.appendChild(dataInput);

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  };

  useEffect(() => {
    getData()
  }, [])

  const columns = useMemo(
    () => [
      {
        accessorKey: 'order_date',
        header: 'Date',
        size: 150,
        Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString(),
      },
      {
        accessorKey: 'receipt_no',
        header: 'Receipt No.',
        size: 150,
      },
      {
        accessorKey: 'poNum',
        header: 'PO NO.',
        size: 150,
      },
      {
        accessorKey: 'vendor_name',
        header: 'PARTY',
        size: 150,
      },
      {
        accessorKey: 'staff_name',
        header: 'Created by',
        size: 150,
      },
      {
        accessorKey: '_id',
        header: 'Action',
        size: 200,
        Cell: ({ cell }) => (
          <div className='flex items-center gap-3'>
            <div onClick={() => handlePrint(cell)}>
              <button><FileText size={20} /></button>
            </div>
            {/* <div onClick={() => alert("Working...")}> */}
            <div onClick={() => navigation(`/create-receipt-form`, { state: { receiptId: cell.getValue() } })}>
              <button><Pencil size={18} /></button>
            </div>
          </div>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
  });

  return (
    <div className="flex-1 px-5 w-full justify-center items-center">
      <div>
        <div className="bg-[#fdfdfd] border mt-4 p-2 flex w-full justify-between items-center rounded-md shadow">
          <div className="flex rounded-md items-center w-1/2">
            <h2 className="font-bold text-[#7286D3]">Receipt Note Report</h2>
          </div>
          <div className="w-50 border p-2 bg-slate-300 rounded-lg font-bold ">
            <Link to="/create-receipt-form" title="Create PO">
              Create Receipt Note
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full min-h-[500px] my-2 rounded-md shadow bg-[#fdfdfd]">
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
};

export default ReceiptNoteReport;
