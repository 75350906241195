import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Checkbox } from '@mui/material';
import api from './Axios/api';



const ApprovalList = () => {

  const [data, setData] = useState([]);
  const navigation = useNavigate();

  const getData = async () => {
    try {
      const res = await api.get(`/purchase/get-approved-item-list`)
      setData(res.data)
      // console.log(res.data);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])


  const columns = useMemo(
    () => [
      {
        accessorKey: 'purchase_approval_date',
        header: 'Date',
        Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString(),
        size: 150,
      },
      {
        accessorKey: 'indent_number',
        header: 'INDENT NO',
        size: 150,
      },
      {
        accessorKey: 'item_title',
        header: 'Item Name',
        size: 150,
      },
      {
        accessorKey: 'party_name',
        header: 'PARTY',
        size: 150,
      },
      {
        accessorKey: 'branch_name',
        header: 'BRANCH',
        size: 150,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    renderToolbarAlertBannerContent: (tableInstance) => {
      const selectedRowIds = Object.keys(tableInstance.table.getState().rowSelection);
      const selectedRows = selectedRowIds.map(id => tableInstance.table.getRow(id).original);
      const ids = selectedRows.map((item) => item._id)
      return (
        <div className='flex items-center gap-3'>
          {tableInstance.selectedAlert}
          <div className='text-sm text-[#36C2CE] cursor-pointer'
            onClick={(e) => { navigation(`/create-po-form`, { state: { ids } }) }}
          >
            Create PO
          </div>
        </div>
      )
    }
  });

  return (
    <div className="flex-1 px-5 w-full justify-center items-center">
      <div className="h-10 px-2 flex justify-between items-center text-violet-500 font-bold rounded-md bg-[#fdfdfd] shadow my-2">
        <div className="text-lg">Approved Item</div>
      </div>
      <div className="w-full min-h-[500px] rounded-md shadow bg-[#fdfdfd]">
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
};

export default ApprovalList;
