import React, { useEffect, useState } from 'react';
import Select from "react-select";
import { Input } from "@mui/material";
import api from "../Axios/api";
import moment from 'moment';

function ReceiptPartyDetail({ formState, setFormState, orderDateDropDown, poNoDropdown, setPoNoDropdown, receiptId }) {
    // It is purchase status dropdown value
    const statusOptions = [
        { label: "Accepted", value: "Accepted" },
        { label: "Rejected", value: "Rejected" },
    ];

    // All Dropdown States
    const [staffNameDropdown, setStaffNameDropdown] = useState([]);
    const [vendorDropdown, setVenderDropdown] = useState([]);
    const [ledgerDropdown, setLedgerDropdown] = useState([]);

    const staffNameList = async () => {
        await api.get(`/staff/get-staff-name`).then((res) => {
            const newData = res.data.map((staff, index) => {
                return { value: staff._id, label: staff.staff_name };
            });
            setStaffNameDropdown(newData);
        }).catch((err) => console.log(err));
    };

    const getVenderList = async () => {
        await api.get(`/vendor/get-name`).then((res) => {
            const newData = res.data.map((vendor, index) => {
                return { value: vendor._id, label: vendor.vendor_name };
            });
            setVenderDropdown(newData);
        }).catch((err) => console.log(err));
    };

    const getLedgerDropdown = async () => {
        await api.get(`/purchase-ledger/get`).then((res) => {
            const newData = res.data.map((ledger, index) => {
                return { value: ledger._id, label: ledger.purchase_ledger_name };
            });
            setLedgerDropdown(newData);
        }).catch((err) => console.log(err));
    };

    const getPoNumberList = async (partyId) => {
        try {
            const res = await api.get(`/receipt-note/get-po-numbers/${partyId}`);
            const newData1 = res.data.map((PoNum) => ({
                value: PoNum._id,
                label: PoNum.po_number,
            }));
            setPoNoDropdown([
                {
                    value: null,
                    label: "Case",
                },
                ...newData1,
            ]);
        } catch (error) {
            console.error("Error fetching PO numbers:", error);
        }
    };

    useEffect(() => {
        staffNameList();
        getVenderList();
        getLedgerDropdown();
    }, []);

    return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                    Receipt No. <span className="text-red-500">*</span>
                </label>
                <Input
                    type="text"
                    multiple
                    name="receipt_no"
                    value={formState.receipt_no}
                    onChange={(e) => { setFormState((prev) => ({ ...prev, receipt_no: e.target.value })) }}
                    className="block w-full rounded border-0 py-1 pl-7 pr-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    placeholder="Receipt No."
                />
            </div>

            <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                    Date <span className="text-red-500">*</span>
                </label>
                <input
                    type="date"
                    name="entry_date"
                    value={formState.entry_date}
                    onChange={(e) => { e.target.value <= moment().format('yyyy-MM-DD') && setFormState((prev) => ({ ...prev, entry_date: e.target.value })) }}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                />
            </div>

            <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">Select Staff <span className="text-red-500">*</span></label>
                <Select
                    options={staffNameDropdown}
                    value={staffNameDropdown.find(option => option.value === formState.staff_id) || null}
                    onChange={(e) => { setFormState((prev) => ({ ...prev, staff_id: e.value })) }}
                    styles={{
                        menuList: () => ({
                            overflowY: "scroll",
                            maxHeight: "200px",
                        }),
                        input: (baseStyles) => ({
                            ...baseStyles,
                            width: "180px"
                        }),
                        placeholder: (baseStyles) => (
                            {
                                ...baseStyles,

                            }
                        )
                    }}
                />
            </div>

            <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                    Party Name <span className="text-red-500">*</span>
                </label>
                <Select
                    isDisabled={receiptId && true}
                    options={vendorDropdown}
                    value={vendorDropdown.find(option => option.value === formState.party_id) || null}
                    onChange={(e) => {
                        setFormState((prev) => ({ ...prev, party_id: e.value }));
                        getPoNumberList(e.value);
                    }}
                    styles={{
                        menuList: () => ({
                            overflowY: "scroll",
                            maxHeight: "200px",
                        }),
                        input: (baseStyles) => ({
                            ...baseStyles,
                            width: "180px"
                        }),
                        control: (base) => ({ ...base, backgroundColor: receiptId && '#E2E8F0' }),
                        singleValue: (base, state) => ({
                            ...base,
                            color: state.isDisabled ? 'black' : '#1A202C',
                        }),
                    }}
                />
            </div>

            <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                    PO Number <span className="text-red-500">*</span>
                </label>
                <Select
                    isDisabled={receiptId && true}
                    options={poNoDropdown}
                    value={poNoDropdown?.find(dropdown => formState.po_id.includes(dropdown.value)) || null}
                    isMulti
                    onChange={(selectedOptions) => {
                        const selectedPoIds = selectedOptions.map(option => option.value);
                        const selectedPoNumbers = selectedOptions.map(option => option.label);

                        if (selectedOptions.length > 0) {
                            setFormState((prev) => ({
                                ...prev,
                                po_id: [...new Set([...prev.po_id, ...selectedPoIds])], // Avoid duplicates in po_id
                                po_number: [...new Set([...prev.po_number, ...selectedPoNumbers])], // Avoid duplicates in po_number
                            }));
                        } else {
                            setFormState((prev) => ({ ...prev, po_id: [], po_number: [] }));
                        }
                    }}
                    styles={{
                        menuList: () => ({
                            overflowY: "scroll",
                            maxHeight: "200px",
                        }),
                        input: (baseStyles) => ({
                            ...baseStyles,
                            width: "180px"
                        }),
                        control: (base) => ({ ...base, backgroundColor: receiptId && '#E2E8F0' }),
                        singleValue: (base, state) => ({
                            ...base,
                            color: state.isDisabled ? 'black' : '#1A202C',
                        }),
                    }}
                />
            </div>

            <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                    Purchase Ledger <span className="text-red-500">*</span>
                </label>
                <Select
                    isDisabled={receiptId && true}
                    options={ledgerDropdown}
                    value={ledgerDropdown.find(option => option.value === formState.purchase_ledger) || null}
                    onChange={(e) => { setFormState((prev) => ({ ...prev, purchase_ledger: e.value })) }}
                    styles={{
                        menuList: () => ({
                            overflowY: "scroll",
                            maxHeight: "200px",
                        }),
                        input: (baseStyles) => ({
                            ...baseStyles,
                            width: "180px"
                        }),
                        control: (base) => ({ ...base, backgroundColor: receiptId && '#E2E8F0' }),
                        singleValue: (base, state) => ({
                            ...base,
                            color: state.isDisabled ? 'black' : '#1A202C',
                        }),
                    }}
                />
            </div>

            <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                    Order Date <span className="text-red-500">*</span>
                </label>
                <Select
                    isDisabled={receiptId && true}
                    options={orderDateDropDown}
                    value={orderDateDropDown.find(option => formState.order_date.map(val => option.value === val))}
                    isMulti
                    onChange={(selectedOptions) => {
                        const selectedValues = selectedOptions.map(option => option.value);
                        selectedOptions.length > 0 ? setFormState((prev) => ({
                            ...prev, order_date: [...prev.order_date, ...selectedValues.filter(val => !prev.order_date.includes(val))]
                        })) : setFormState((prev) => ({ ...prev, order_date: [] }));
                    }}
                    styles={{
                        menuList: () => ({
                            overflowY: "scroll",
                            maxHeight: "200px",
                        }),
                        input: (baseStyles) => ({
                            ...baseStyles,
                            width: "180px"
                        }),
                        control: (base) => ({ ...base, backgroundColor: receiptId && '#E2E8F0' }),
                        singleValue: (base, state) => ({
                            ...base,
                            color: state.isDisabled ? 'black' : '#1A202C',
                        }),
                    }}
                />
            </div>

            <div className={`flex flex-col ${formState.receipt_status === "Rejected" && "bg-red-400"}`}>
                <label className="mb-2 font-semibold text-gray-700">
                    Purchase Status <span className="text-red-500">*</span>
                </label>
                <Select
                    options={statusOptions}
                    value={statusOptions.find(option => option.value === formState.receipt_status) || null}
                    onChange={(selectedOption) => setFormState((prev) => ({ ...prev, receipt_status: selectedOption.value }))}
                    styles={{
                        menuList: () => ({
                            overflowY: "scroll",
                            maxHeight: "200px",
                        }),
                        input: (baseStyles) => ({
                            ...baseStyles,
                            width: "180px"
                        }),
                        placeholder: (baseStyles) => (
                            {
                                ...baseStyles,
                            }
                        )
                    }}
                />
            </div>
        </div>
    )
}

export default ReceiptPartyDetail