import React, { useEffect, useState } from "react";

import { Autocomplete, Input, select, TextField } from "@mui/material";
import { CircleMinus, CirclePlus } from "lucide-react";
import api from "./Axios/api";
import Swal from "sweetalert2";

const CreateReceiptNoteFrom = () => {
  const initialReceiptItems = [
    {
      receipt_item_id: "",
      receipt_item_amt: 0,
      receipt_item_qty: 0,
      receipt_total_qty: 0,
      receipt_rem_qty: 0,
      receipt_item_rate: 0,
      receipt_item_remark: "",
      receipt_purchase_id: "" // Another example of if-else

    },
  ];

  const [dropItem, setDropItems] = useState([]);
  const [dropLedger, setDropLedger] = useState([]);
  const [dropVender, setdropVender] = useState([]);
  const [staffName, setStaffName] = useState([]);
  // const [polist, setDropPolist] = useState([]);

  const [polist, setPolist] = useState([]);
  const [formState, setFormState] = useState({
    receipt_no: "",
    entry_date: null,
    staff_id: "",
    party_id: "",
    po_number: "",
    purchase_ledger: "",
    order_date: null,
    receipt_status: "",
    receiptitems: initialReceiptItems,
    terms_of_payment: "",
    other_references: "",
    terms_of_delivery: "",
    receipt_doc_no: "",
    dispatched_through: "",
    destination: "",
    carrier_name: "",
    bill_of_lading: "",
    motor_vehicle_no: "",
    insurance_policy_no: "",
    contact_person: "",
    vehicle_no: "",
    driver_mobile_no: "",
    challan_no: "",
    weight_slip_no: "",
    fright_advice_no: "",
    stores_remarks: "",
    challan_date: null,
    gate_entry_no: "",
    fright_rs: "",
    receipt_challan_img: [],
    receipt_weight_slip_img: [],
    receipt_item_img: [],
    receipt_invoice_img: [],
    receipt_quality_img: [],
    receipt_weight_kg: "",
    receipt_narration: "",
    weightSlipDate: "",
  });

  const handleItemChange = (index, event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      receiptitems: prevState.receiptitems.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      ),
    }));
    if (name == "receipt_item_qty") {

      setFormState((prevState) => ({
        ...prevState,
        receiptitems: prevState.receiptitems.map((item, i) =>
          i === index ? {
            ...item,
            receipt_rem_qty: item.receipt_total_qty - value,
            receipt_item_amt: item.receipt_item_rate * value,
          } : item
        ),
      }));
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
    if (name === 'party_id') {
      getPoNumber(value);
    }
    if (name === 'po_number') {
      getpoitemlist(value);
    }
  };
  const validateForm = () => {

    const { receipt_no, po_number, party_id, staff_id, entry_date, order_date, receipt_status } = formState;
    if (!po_number || !receipt_no || !party_id || !staff_id || !entry_date || !order_date || !receipt_status) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please fill all the required fields marked with *',
      });
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    if (!validateForm()) return;
    const formData = new FormData();

    // Append form fields
    for (const key in formState) {
      if (formState.hasOwnProperty(key)) {
        if (formState[key] instanceof FileList) {
          // If it's a file input, append each file separately
          Array.from(formState[key]).forEach(file => {
            formData.append(key, file);
          });
        } else if (Array.isArray(formState[key])) {
          // If the field is an array (e.g., receiptItems), append each item separately
          formState[key].forEach((item, index) => {
            for (const itemKey in item) {
              formData.append(`${key}[${index}][${itemKey}]`, item[itemKey]);
            }
          });
        } else {
          // Otherwise, append the field value
          formData.append(key, formState[key]);
        }
      }
    }

    api
      .post("/receipt-note/add", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        // handle the response
        // console.log("Form submission successful:", response.data);
        Swal.fire({
          title: "Submited!",
          text: "Your form has been Submited.",
          icon: "success"
        });
      })
      .catch((error) => {
        // handle the error
        console.error("There was an error submitting the form:", error.response.data);
      });
  };

  const getPoNumber = async (partyId) => {
    try {
      const res = await api.get(`/receipt-note/get-po-number/${partyId}`);
      const data = res.data;
      // console.log(data);
      const newData1 = data.map((PoNum) => ({
        // value: PoNum._id, 
        value: PoNum.po_number,
        label: PoNum.po_number,
      }));
      setPolist(newData1);
    } catch (error) {
      console.error("Error fetching PO numbers:", error);
    }
  };

  const submitAlertMsg = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Submited it!"
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmit();
      }
    });
  }

  const getpoitemlist = async (poNumber) => {
    deleteItem(0)
    try {
      const res = await api.get(`/po-detail/po-detail-with-po-num/?poNumber=${poNumber}`);
      const data = res.data;
      const newData1 = data.map((PoNum) => {
        // Define variables or perform any checks here if needed
        const someCondition = PoNum.purchase_remaining_qty >= 0; // Example condition
        if (PoNum.purchase_rem_status != 0) {
          return setFormState((prevState) => ({
            ...prevState,
            receiptitems: [
              ...prevState.receiptitems,
              {
                receipt_item_id: PoNum.item_name,
                receipt_item_qty: PoNum.purchase_qty,
                receipt_total_qty: someCondition ? PoNum.purchase_remaining_qty : PoNum.purchase_qty, // Example if-else usage
                receipt_item_rate: PoNum.rate,
                receipt_rem_qty: someCondition ? PoNum.purchase_remaining_qty : 0,
                receipt_item_remark: PoNum.remark,
                receipt_item_amt: PoNum.rate * (someCondition ? PoNum.purchase_remaining_qty : PoNum.purchase_qty),
                receipt_purchase_id: PoNum.purchase_id // Another example of if-else
              },
            ],
          }))
        }
      });
    } catch (error) {
    }
  }
  const staffNames = async () => {
    await api
      .get(`/staff/get-staff-name`)
      .then((res) => {
        const data = res.data;
        const newData = data.map((staff, index) => {
          let value = staff._id;
          return { value: value, label: staff.staff_name };
        });
        setStaffName(newData);
      })
      .catch((err) => console.log(err));
  };
  const getGroupItems = async () => {
    await api.get(`/purchase-item/get-item-name`).then((res) => {
      const data = res.data;
      const newData = data.map((item, index) => {
        let value = item._id;
        return { value: value, label: item.item_title };
      });
      setDropItems(newData);
    });
  };
  const getGroupLedger = async () => {
    await api.get(`/purchase-ledger/get`).then((res) => {
      const data = res.data;
      // console.log(data);
      const newData = data.map((ledger, index) => {
        let value = ledger._id;
        return { value: value, label: ledger.purchase_ledger_name };
      });
      setDropLedger(newData);
    });
  };
  const getGroupVender = async () => {
    await api.get(`/vendor/get-name`).then((res) => {
      const data = res.data;
      // console.log(data);
      const newData = data.map((vendor, index) => {
        let value = vendor._id;
        return { value: value, label: vendor.vendor_name };
      });
      setdropVender(newData);
    });
  };
  useEffect(() => {
    staffNames();
    getGroupItems();
    getGroupLedger();
    getGroupVender();
  }, []);

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormState({
      ...formState,
      [name]: files[0],
    });
  };
  const statusOptions = [
    { label: "Accepted", value: "Accepted" },
    { label: "Rejected", value: "Rejected" },
    // Add more options as needed
  ];
  const addItem = () => {
    setFormState((prevState) => ({
      ...prevState,
      receiptitems: [
        ...prevState.receiptitems,
        {
          receipt_item_id: "",
          receipt_item_qty: 0,
          receipt_item_rate: 0,
          receipt_item_amt: 0,
          receipt_rem_qty_qty: 0,
          receipt_item_remark: "",
          receipt_purchase_id: ""
        },
      ],
    }));
  };

  const deleteItem = (index) => {
    setFormState((prevState) => ({
      ...prevState,
      receiptitems: prevState.receiptitems.filter((_, i) => i !== index),
    }));
  };


  return (
    <div className="flex-1 px-5 w-full justify-center receiptitems-center">
      <div>
        <div className="bg-[#fdfdfd] border mt-4 p-2 flex w-full justify-between receiptitems-center rounded-md shadow">
          <div className="flex rounded-md receiptitems-center w-1/2">
            <h2 className="font-bold text-[#7286D3]">Receipt Note From</h2>
          </div>
        </div>
      </div>
      <form className="">
        <div className="w-full min-h-[500px] px-6 py-4 my-2 rounded-md shadow bg-[#fdfdfd]">
          <div>
            <h2 className="text-xl font-semibold px-6 mb-4 text-center my-4">
              Party Details

            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                  Receipt No. <span className="text-red-500">*</span>
                </label>
                <Input
                  type="text"
                  name="receipt_no"
                  value={formState.receipt_no}
                  onChange={handleChange}
                  className="block w-full rounded border-0 py-1 pl-7 pr-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                  placeholder="Receipt No."
                />
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                  Date <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  name="entry_date"
                  value={formState.entry_date}
                  onChange={handleChange}
                  className="block w-full rounded border-0 py-1.5 pl-7 pr-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                />
              </div>
              <div className="flex flex-col">
                <Autocomplete
                  // multiple
                  options={staffName}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Staff Name"
                      variant="outlined"
                      fullWidth
                      marginTop="50px"
                    />
                  )}
                  value={staffName.find((option) => option.value === formState.staff_id) || null}
                  onChange={(event, newValue) => {
                    handleChange({ target: { name: "staff_id", value: newValue?.value || "" } });
                  }}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                />
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                  Party Name <span className="text-red-500">*</span>
                </label>
                <Autocomplete
                  id="party_id"
                  options={dropVender}
                  getOptionLabel={(option) => option.label}
                  value={dropVender.find((item) => item.value === formState.party_id) || null}
                  onChange={(event, newValue) => {
                    handleChange({
                      target: {
                        name: "party_id",
                        value: newValue ? newValue.value : "",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Party Name"
                      placeholder="Start typing..."
                      className="block w-full rounded border-0    ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    />
                  )}
                />
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                  PO Number
                </label>
                <Autocomplete
                  id="po_number"
                  options={polist}
                  getOptionLabel={(option) => option.label}
                  value={polist.find((item) => item.value === formState.po_number) || null}
                  onChange={(event, newValue) => {
                    handleChange({
                      target: {
                        name: "po_number",
                        value: newValue ? newValue.value : "",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select PO Number"
                      placeholder="Start typing..."
                      className="block w-full rounded border-0  pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    />
                  )}
                />
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                  Purchase Ledger <span className="text-red-500">*</span>
                </label>
                <Autocomplete
                  id="purchase_ledger"
                  options={dropLedger}
                  getOptionLabel={(option) => option.label}
                  value={dropLedger.find((item) => item.value === formState.purchase_ledger) || null}
                  onChange={(event, newValue) => {
                    handleChange({
                      target: {
                        name: "purchase_ledger",
                        value: newValue ? newValue.value : "",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Item"
                      placeholder="Start typing..."
                      className="block w-full rounded border-0  pl-7 pr-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    />
                  )}
                />
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                  Order Date
                </label>
                <input
                  type="date"
                  name="order_date"
                  value={formState.order_date}
                  onChange={handleChange}
                  className="block w-full rounded border-0 py-1.5 pl-7 pr-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                />
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                  Purchase Status <span className="text-red-500">*</span>
                </label>
                <Autocomplete
                  id="receipt_status"
                  options={statusOptions}
                  getOptionLabel={(option) => option.label}
                  value={statusOptions.find((item) => item.value === formState.receipt_status) || null}
                  onChange={(event, newValue) => {
                    handleChange({
                      target: {
                        name: "receipt_status",
                        value: newValue ? newValue.value : "",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Status"
                      placeholder="Start typing..."
                      className="block w-full rounded border-0  pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    />
                  )}
                />
              </div>
            </div>
            <div className="mt-6">
              {/* <label className="mb-2 font-semibold text-gray-700">Items</label> */}
              {formState.receiptitems.map((item, index) => (
                <div
                  key={index}
                  className="grid grid-cols-1 md:grid-cols-7 gap-6 mb-4"
                >
                  <div className="">
                    <label className="mb-2 font-semibold text-gray-700">
                      Items Name <input type="hidden" name="receipt_purchase_id"
                        value={item.receipt_purchase_id} />
                    </label>
                    <select
                      name="receipt_item_id"
                      disabled="true"
                      value={item.receipt_item_id}
                      onChange={(e) => handleItemChange(index, e)}
                      className="block w-full rounded border-0 py-1.5 pl-7 pr-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    >
                      <option>Select Purchase Ledger</option>
                      {dropItem.map((iteml, index) => (
                        <option key={index} value={iteml.value}>
                          {iteml.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="">
                    <label className="mb-2 font-semibold text-gray-700">
                      Items Qty <span className="text-xs">(Total qty {item.receipt_total_qty})</span>
                    </label>
                    <input
                      type="number"
                      name="receipt_item_qty"
                      value={item.receipt_item_qty}
                      onChange={(e) => handleItemChange(index, e)}
                      className="block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                      placeholder="Quantity"
                    />
                    <input
                      type="hidden"
                      name="receipt_total_qty"
                      value={item.receipt_total_qty}
                      onChange={(e) => handleItemChange(index, e)}
                      className="block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                      placeholder="Quantity"
                    />
                  </div>
                  <div className="">
                    <label className="mb-2 font-semibold text-gray-700">
                      Items Rem. Qty
                    </label>
                    <input
                      disabled="true"
                      type="number"
                      name="receipt_rem_qty"
                      value={item.receipt_rem_qty}
                      onChange={(e) => handleItemChange(index, e)}
                      className="block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                      placeholder="Remaining Quantity"
                    />
                  </div>

                  <div className="">
                    <label className="mb-2 font-semibold text-gray-700">
                      Items Rate
                    </label>
                    <input
                      type="number"
                      disabled="true"
                      name="receipt_item_rate"
                      value={item.receipt_item_rate}
                      onChange={(e) => handleItemChange(index, e)}
                      className="block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                      placeholder="Rate"
                    />
                  </div>
                  <div className="">
                    <label className="mb-2 font-semibold text-gray-700">
                      Item Amt.
                    </label>
                    <input
                      type="number"
                      disabled="true"
                      name="receipt_item_amt"
                      value={item.receipt_item_amt}
                      onChange={(e) => handleItemChange(index, e)}
                      className="block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                      placeholder="Amount"
                    />
                  </div>
                  <div className="">
                    <label className="mb-2 font-semibold text-gray-700">
                      Remark
                    </label>
                    <input
                      type="text"
                      multiple={true}
                      name="receipt_item_remark"
                      value={item.receipt_item_remark}
                      onChange={(e) => handleItemChange(index, e)}
                      className="block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                      placeholder="Remark"
                    />
                  </div>
                  <div className="flex receiptitems-end">
                    <CirclePlus onClick={addItem} color="purple" size={30} className="my-6  mx-1" />
                    <CircleMinus onClick={() => deleteItem(index)} size={30} color={"red"} className="my-6" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full min-h-[500px] px-6 py-4 my-6 rounded-md shadow bg-[#fdfdfd]">
          <div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6  py-4 border-b-0">
              {/* <div className="flex flex-col"> */}
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                  Terms of Payment
                </label>
                <input
                  type="text"
                  name="terms_of_payment"
                  value={formState.terms_of_payment}
                  onChange={handleChange}
                  className="block w-full rounded border-0 py-1.5 pl-7 pr-10 bg-slate-200  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                  placeholder="Terms of Payment"
                />
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                  Other References
                </label>
                <input
                  type="text"
                  name="other_references"
                  value={formState.other_references}
                  onChange={handleChange}
                  className="block w-full rounded border-0 py-1.5 pl-7 pr-10 bg-slate-200  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                  placeholder="Other References"
                />
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                  Terms of Delivery
                </label>
                <input
                  type="text"
                  name="terms_of_delivery"
                  value={formState.terms_of_delivery}
                  onChange={handleChange}
                  className="block w-full rounded border-0 py-1.5 pl-7 pr-10 bg-slate-200  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                  placeholder="Terms of Delivery"
                />
              </div>
              {/* </div> */}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6  py-4 border-b-0">
              <div className="flex flex-col font-bold text-lg text-center">
                Receipt Details
              </div>
              <div className="flex flex-col font-bold text-lg text-center">
                Other Details
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 border-b-4 py-4">
              {/* <h2 className="text-xl font-semibold my-4">Receipt Details</h2> */}
              <div className="flex flex-col border-r-4 px-4 ">
                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-700">
                    Receipt Doc No.
                  </label>
                  <input
                    type="text"
                    name="receipt_doc_no"
                    value={formState.receipt_doc_no}
                    onChange={handleChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    placeholder="Receipt Doc No."
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-700">
                    Dispatched Through
                  </label>
                  <input
                    type="text"
                    name="dispatched_through"
                    value={formState.dispatched_through}
                    onChange={handleChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    placeholder="Dispatched Through"
                  />
                </div>

                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-700">
                    Destination
                  </label>
                  <input
                    type="text"
                    name="destination"
                    value={formState.destination}
                    onChange={handleChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    placeholder="Dispatched Through"
                  />
                </div>

                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-700">
                    Carrier Name
                  </label>
                  <input
                    type="text"
                    name="carrier_name"
                    value={formState.carrier_name}
                    onChange={handleChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    placeholder="Dispatched Through"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-700">
                    Bill Of Lading
                  </label>
                  <input
                    type="text"
                    name="bill_of_lading"
                    value={formState.bill_of_lading}
                    onChange={handleChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    placeholder="Dispatched Through"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-700">
                    Motor Vehicle No.
                  </label>
                  <input
                    type="text"
                    name="motor_vehicle_no"
                    value={formState.motor_vehicle_no}
                    onChange={handleChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    placeholder="Dispatched Through"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-700">
                    Insurance Policy No.
                  </label>
                  <input
                    type="text"
                    name="insurance_policy_no"
                    value={formState.insurance_policy_no}
                    onChange={handleChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    placeholder="Dispatched Through"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-700">
                    Contact Person
                  </label>
                  <input
                    type="text"
                    name="contact_person"
                    value={formState.contact_person}
                    onChange={handleChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    placeholder="Dispatched Through"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-700">
                    Vehicle No.
                  </label>
                  <input
                    type="text"
                    name="vehicle_no"
                    value={formState.vehicle_no}
                    onChange={handleChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    placeholder="Dispatched Through"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-700">
                    Driver Mobile No.
                  </label>
                  <input
                    type="text"
                    max={10}
                    name="driver_mobile_no"
                    value={formState.driver_mobile_no}
                    onChange={handleChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    placeholder="Dispatched Through"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-700">
                    Challan No
                  </label>
                  <input
                    type="text"
                    name="challan_no"
                    value={formState.challan_no}
                    onChange={handleChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    placeholder="Challan No"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-700">
                    Weight Slip No
                  </label>
                  <input
                    type="text"
                    name="weight_slip_no"
                    value={formState.weight_slip_no}
                    onChange={handleChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    placeholder="Weight Slip No"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-700">
                    Fright Advice No
                  </label>
                  <input
                    type="text"
                    name="fright_advice_no"
                    value={formState.fright_advice_no}
                    onChange={handleChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    placeholder="Fright Advice No."
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-700">
                    Stores Remarks
                  </label>
                  <input
                    type="text"
                    name="stores_remarks"
                    value={formState.stores_remarks}
                    onChange={handleChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    placeholder="Stores Remarks"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-700">
                    Challan Date
                  </label>
                  <input
                    type="date"
                    name="challan_date"
                    value={formState.challan_date}
                    onChange={handleChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    placeholder="Challan Date"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-700">
                    Weight Slip Date
                  </label>
                  <input
                    type="date"
                    name="weightSlipDate"
                    value={formState.weightSlipDate}
                    onChange={handleChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    placeholder="Weight Slip Date"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-700">
                    Gate Entry No
                  </label>
                  <input
                    type="text"
                    name="gate_entry_no"
                    value={formState.gate_entry_no}
                    onChange={handleChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    placeholder="Gate Entry No"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-700">
                    Fright Rs
                  </label>
                  <input
                    type="text"
                    name="fright_rs"
                    value={formState.fright_rs}
                    onChange={handleChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    placeholder="Fright Rs"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6  my-6 ">
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                  Challan Image
                </label>
                <input
                  type="file"
                  name="receipt_challan_img"
                  onChange={handleFileChange}
                  className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                />
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                  Weight Slip Image
                </label>
                <input
                  type="file"
                  name="receipt_weight_slip_img"
                  onChange={handleFileChange}
                  className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                />
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                  Item Image
                </label>
                <input
                  type="file"
                  name="receipt_item_img"
                  onChange={handleFileChange}
                  className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                />
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                  Invoice Image
                </label>
                <input
                  type="file"
                  name="receipt_invoice_img"
                  onChange={handleFileChange}
                  className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                />
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                  Quality Checked Image
                </label>
                <input
                  type="file"
                  name="receipt_quality_img"
                  onChange={handleFileChange}
                  className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                />
              </div>

              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">
                  Weight Kg
                </label>
                <input
                  type="text"
                  name="receipt_weight_kg"
                  value={formState.receipt_weight_kg}
                  onChange={handleChange}
                  className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                  placeholder="Weight Kg"
                />
              </div>
            </div>
            <div className="flex flex-col mt-6">
              <label className="mb-2 font-semibold text-gray-700">
                Narration
              </label>
              <textarea
                name="receipt_narration"
                value={formState.receipt_narration}
                onChange={handleChange}
                className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                placeholder="Narration"
                rows="4"
              />
            </div>

            <div className="flex justify-end mt-6">
              <button
                type="button"
                className="px-6 py-2 mx-1 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition"
                onClick={submitAlertMsg}
              >
                Submit
              </button>
              <button
                type="reset"
                className="px-6 py-2 mx-1 bg-red-600 text-white rounded-md hover:bg-red-700 transition"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateReceiptNoteFrom;
